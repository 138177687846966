export enum NOTE_TYPES {
  NOTE = 'NOTE',
  HIGHLIGHT = 'HIGHLIGHT',
}

export enum NOTE_HIGHLIGHT_COLORS {
  RED = 'RED',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  PURPLE = 'PURPLE',
}
